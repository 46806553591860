import util from "../utils/util";

export default class SettingService {
  getService(id) {
    return util.sendApiRequest("/setting/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  editService(service) {
    return util.sendApiRequest("/setting", "PUT", true, service).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  listService(start, length, searchTxt, searchField) {
    let url = "/setting/list/admin/" + start + "/" + length;
    if (searchTxt && searchField) {
      url =
        "/setting/list/" +
        start +
        "/" +
        length +
        "/" +
        searchTxt +
        "/" +
        searchField.toLowerCase();
    }

    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  listServiceWithoutAuth(start, length, searchTxt, searchField) {
    let url = "/setting/list/" + start + "/" + length;
    if (searchTxt && searchField) {
      url =
        "/setting/list/" +
        start +
        "/" +
        length +
        "/" +
        searchTxt +
        "/" +
        searchField.toLowerCase();
    }

    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  addService(service) {
    return util.sendApiRequest("/setting", "POST", true, service).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  deleteService(id) {
    return util.sendApiRequest("/setting/" + id, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getCertainValue(keys) {
    return util
      .sendApiRequest("/setting/getcertainValues", "POST", true, keys)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      );
  }

  addBlockCountryList(body) {
    return util
      .sendApiRequest("/setting/blockedcountry", "POST", true, body)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      );
  }
  listBlockedCountry(start, length, searchTxt, searchField) {
    let url = "/setting/blockedcountrylist/" + start + "/" + length;
    if (searchTxt && searchField) {
      url =
        "/setting/blockedcountrylist/" +
        start +
        "/" +
        length +
        "/" +
        searchTxt +
        "/" +
        searchField.toLowerCase();
    }

    return util.sendApiRequest(url, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  deleteBlockedCountry(id) {
    return util
      .sendApiRequest("/setting/blockedcountry/" + id, "DELETE", true)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      );
  }
}
